import React from "react";
import theme from "theme";
import { Theme, Box, Em, Text, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"adipec2024"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px">
			<Override slot="linkBox7" color="white" />
			<Override slot="text17" color="--accentOrange" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box min-width="100px" min-height="100px" md-padding="120px 16px 120px 16px">
			<Box
				min-width="100px"
				min-height="100px"
				align-items="center"
				display="flex"
				flex-direction="column"
				justify-content="center"
				flex-wrap="no-wrap"
				grid-row-gap="36px"
				padding="50px 16px 100px 16px"
				margin="0px auto 0px auto"
				max-width="1348px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-items="flex-start"
					justify-content="center"
					grid-row-gap="16px"
				>
					<Text
						margin="0 0 0 0"
						word-wrap="break-word"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background=" #080808"
						background-clip="text"
						font="--headline3"
						min-width="1px"
						min-height="1px"
					>
						Press release: SPhotonix and ADIPEC
						<br />
						<br />
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Date: 1st November 2024{"\n\n\n\n\n\n"}
						</Em>
					</Text>
					<Text
						margin="0 0 0 0"
						word-wrap="break-word"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background=" #080808"
						background-clip="text"
						font="--bodyText17SemiBold"
					/>
					<Text margin="0px 0px 0px 0px" text-align="justify" font="--bodyText21Regular">
						Global Energy Sector Looks to SPhotonix to Solve Data Storage Challenges{"\n\n"}
						<br />
						<br />
						•{"       "}
						<Link
							href="https://sphotonix.com"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Em
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								SPhotonix
							</Em>
						</Link>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{" "}launches data storage capability at ADIPEC 2024 capable of up to 360
terabytes of data
						</Em>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						•{"       "}
						<Link
							href="https://www.adipec.com/conferences/energy-ai-conference/?day=all"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Em
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Delegates
can participate in a live demo of 5D memory crystal data storage on Tuesday 4th
November 2.00 pm (GST)
							</Em>
						</Link>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						•{"      \n"}
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Pioneered by Prof. Peter
Kazansky at University of Southampton, their 5D memory crystal technology can
solve energy sector’s data storage issues
						</Em>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						•{"      \n"}
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Rise in energy use of data
centres and storage along with AI has triggered mass demand for effective
solutions
						</Em>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						Newark,
Delaware, November 1st :{" "}
						<Link
							href="https://sphotonix.com"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							SPhotonix
						</Link>
						{" "}has launched it’s new data storage capability using 5D memory crystal
technology at the global ADIPEC event in Abu Dhabi. The event, which attracts
the biggest energy and tech brands, showcases the latest innovations in the
world’s energy sector. A product from the University of Southampton’s
Optoelectronics Research team, SPhotonix’s 5D memory crystal has the capability
to store up to 360 terabytes of data on 5 inch glass. This follows hot on the
team’s success in etching the human genome on 5D memory crystals which garnered
worldwide attention.{"\n\n"}
						<br />
						<br />
						Invited to attend the year’s event, Professor Peter Kazansky, SPhotonix
Co-founder and Chief Science Officer, who leads the research team, along with
Ilya Kazansky, Co-founder and CEO, and Garrett MacDonald, Head of Business
Development are meeting with key members of the global energy sector to bring
this ground-breaking technology into service. ADIPEC delegates have the
opportunity to participate in a interactive demo live streamed from SPhotonix
femtosecond lab in the UK, showing real-time data inscription into a quartz
crystal on
						<Link
							href="https://www.adipec.com/conferences/energy-ai-conference/?day=all"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							{" "}Tuesday 4th November at 2.00 pm (GST).
						</Link>
						{"  \n\n"}
						<br />
						<br />
						Commenting on this launch, Ilya Kazansky, CEO and Co-founder at
SPhotonix said:{"\n\n"}
						<br />
						<br />
						“It is predicted that by 2027, data storage will top 300 billion
terabytes, fuelled in part by the rise in AI use, which rapidly scales the
issue of how to store data efficiently and with minimal impact on the
environment. Traditional data storage solutions create substantial CO2
emissions and unsustainable energy needs. Delivering unprecedented data storage
longevity with low environmental impact, SPhotonix’s technology meets future
storage demands without compromising the planet.”{"\n\n"}
						<Link
							href="https://www.5dmemorycrystal.com"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							5D
Memory Crystals
						</Link>
						{" "}are created using
groundbreaking technology, where ultrafast light pulses are fired into quartz
glass, using high precision, computer controlled laser based systems.  The technology enables creation of nano
structures inside the 3 dimensions of a glass plate, further extended by an
additional 2 optical dimensions, namely birefringence and strength retardance.
In combination this enables ultra-dense storage of data, not on the surface,
but deep inside the glass plate, making it the most durable storage medium
available today.{"\n\n\n\n\n\n\n\n"}
					</Text>
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			padding-top="100px"
			padding-bottom="24px"
			padding-left="190px"
			padding-right="190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="flex"
			sm-display="flex"
			sm-padding="24px 16px 24px 16px"
			sm-height="min-content"
			sm-grid-row-gap="64px"
			quarkly-title="Footer"
			grid-row-gap="0px"
			grid-column-gap="0px"
			padding="0px 190px 24px 190px"
			md-padding="0px 16px 24px 16px"
		>
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				sm-display="flex"
				sm-grid-row-gap="32px"
				sm-height="auto"
			>
				<Box align-self="stretch" height="1px" background="#B1B1B1" width="100%" />
				<Components.Footer />
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
			<link
				href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-favicon.ico?v=2025-03-25T15:31:48.817Z"}
				type={"image/x-icon"}
				rel={"icon"}
				place={"endOfHead"}
				rawKey={"67e2cb378695622ce9816272"}
			/>
		</RawHtml>
	</Theme>;
});